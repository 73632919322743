import axios from "axios";
import React, { useState, useEffect } from "react";
import {
  Tabs,
  Form,
  Input,
  Button,
  InputNumber,
  List,
  Divider,
  Space,
  Select,
  Upload,
} from "antd";
import { useDrawer } from "../../../context/drawer";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import ColumnGroup from "antd/es/table/ColumnGroup";

const { Option } = Select;

export default function PackageUpdateForm() {
  const { drawerData, setDrawerData } = useDrawer();
  const [selectedColorId, setSelectedColorId] = useState(null);
  const [selectedSizeId, setSelectedSizeId] = useState(null);
  const [colorlistid,setcolorlistid]= useState([]);
  const [sizes, setSizes] = useState([]); // Initialize sizes as an empty array
  const [colors, setColors] = useState([]); // Initialize colors as an empty array
  const [fileList, setFileList] = useState([]);
  const [form] = Form.useForm();
  const { token } = useSelector((state) => state.auth);
  console.log(colorlistid);
  console.log("drawerData", drawerData)



   // Fetch the product images based on product ID
   const fetchProductImages = async (productId) => {
    try {
      const response = await axios.post(
        "https://jutiepie.in/api/product.php",
        {
          action: "getProductImages",
          Product_Id: productId,
        }
      );
      const images = response.data.images.map((image) => ({
        uid: image.id, // Unique ID for Ant Design Upload component
        name: image.image_name,
        status: "done",
        url: image.image_url, // URL to show the image
      }));
      setFileList(images);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  // Initial load to set the product values from drawerData
  useEffect(() => {
    if (drawerData.value) {
      form.setFieldsValue({
        name: drawerData.value.Product_Name,
        description: drawerData.value.Product_Desc,
        tax: drawerData.value.tax,
        product_type: drawerData.value.product_type,
        delivery_charges: drawerData.value.delivery_charges,
        Category_Id:drawerData.value.Category_Id,
        drawerData: drawerData.value
      });
      setSizes(drawerData.value.size || []); // Set sizes with a fallback to an empty array
    }
  }, [drawerData, form]);

console.log(drawerData.value.Category_Id);
  // Handle size change and update index value based on selectedSizeId
  useEffect(() => {
    const indexOfSelectedSize = sizes.findIndex(
      (size) => size.Size_Id === selectedSizeId
    );
    if (indexOfSelectedSize !== -1) {
      const colorsData = sizes[indexOfSelectedSize]?.colors || []
      setColors(colorsData); // Set colors with a fallback to an empty array
      setSelectedColorId(colorsData.length > 0 ? colorsData[0].color_id : null)
    }
  }, [selectedSizeId, sizes]);

  // useEffect(() => {
  //   const selectedColor = colors.find(color => color.product_color_id === selectedColorId);
  //   console.log("selectedColor",selectedColor)
  //   if (selectedColor) {
  //     form.setFieldsValue({
  //       colorname: selectedColor.color_name,
  //       colorcode: selectedColor.color_code,
  //       quantity: selectedColor.product_qty,
  //       price: selectedColor.product_price,
  //       feature: selectedColor.product_feature, // Assuming you want to set this too
  //     });
  //     setFileList(selectedColor.images)
  //   } else {
  //     form.resetFields(['colorname', 'colorcode', 'quantity', 'price', 'feature']);
  //   }
  // }, [selectedColorId, colors, form]);


  useEffect(() => {
    const selectedColor = colors.find(color => color.product_color_id === selectedColorId);
    console.log("selectedColor", selectedColor);
    setcolorlistid(selectedColor);
    if (selectedColor) {
      form.setFieldsValue({
        colorname: selectedColor.color_name,
        colorcode: selectedColor.color_code,
        quantity: selectedColor.product_qty,
        price: selectedColor.product_price,
        feature: selectedColor.product_feature, // Assuming you want to set this too
      });
  
      // Format the images for the Upload component
      const formattedImages = (selectedColor.images || []).map((image) => ({
        uid: image.product_images_id, // Unique identifier
        name: image.product_image.split('/').pop(), // Extract image name from URL
        status: "done", // Set status as done
        url: image.product_image, // The image URL
      }));
  
      setFileList(formattedImages); // Set the formatted images to fileList
    } else {
      form.resetFields(['colorname', 'colorcode', 'quantity', 'price', 'feature']);
      setFileList([]); // Clear the fileList if no color is selected
    }
  }, [selectedColorId, colors, form]);
  

  // Product Update API Call
  const handleFormSubmit = async (values) => {
    console.log(values)
    try {
      const formData = new FormData();
      formData.append("Product_Id", drawerData.value.Product_Id);
      formData.append("Product_Name", values.name);
      formData.append("Product_Desc", values.description);
      formData.append("tax", values.tax);
      formData.append("product_type", values.product_type);
      formData.append("delivery_charges", values.delivery_charges);
      formData.append("token", token);
      formData.append("Category_Id", drawerData.value.Category_Id);
      formData.append("action", "updateproduct");

      const response = await axios.post(
        "https://jutiepie.in/api/product.php",
        formData
      );
      console.log("Update API Response:", response.data);
      alert("Product updated successfully");
    } catch (error) {
      console.error("Error updating product:", error);
    }
  };

  console.log(selectedColorId);

  // Add Size API Call
  const sendProductDetails = async (values) => {
    try {
      const formData = new FormData();
      formData.append("action", "addProductSizesingle");
      formData.append("token", token);
      formData.append("width", values.width);
      formData.append("height", values.height);
      formData.append("length", values.length);
      formData.append("weight", values.weight);
      formData.append("Product_Id", drawerData.value.Product_Id);

      const response = await axios.post(
        "https://jutiepie.in/api/product.php",
        formData
      );
      console.log("API response:", response.data);
      form.resetFields();
    } catch (error) {
      console.error("Error adding product size:", error);
    }
  };

  // Update Size API Call
  const updateProductDetails = async (values) => {
    console.log("values",values)
    try {
      const formData = new FormData();
      formData.append("action", "updatesize");
      formData.append("size_id", selectedSizeId);
      formData.append("width", values.width);
      formData.append("height", values.height);
      formData.append("length", values.length);
      formData.append("weight", values.weight);
      formData.append("Product_Id", drawerData.value.Product_Id);

      const response = await axios.post(
        "https://jutiepie.in/api/product.php",
        formData
      );
      console.log("API response:", response.data);
      alert("Size updated successfully");
      form.resetFields();
    } catch (error) {
      console.error("Error updating product size:", error);
    }
  };

  // Add or Update Color API Call
  const sendcolorDetails = async (values) => {
    try {
      const formDataToSend = new FormData();
      const action = selectedColorId ? "updatecolor" : "AddColor";
      formDataToSend.append("action", action);

      if (selectedColorId) {
        formDataToSend.append("product_color_id", selectedColorId);
      }

      formDataToSend.append("color_name", values.colorname);
      formDataToSend.append("color_code", values.colorcode);
      formDataToSend.append("product_qty", values.quantity);
      formDataToSend.append("product_price", values.price);
      formDataToSend.append("product_feature", values.feature);
      formDataToSend.append("Size_Id", selectedSizeId);

      const response = await axios.post(
        "https://jutiepie.in/api/product.php",
        formDataToSend
      );

      console.log("API response:", response.data);
      alert("Color updated successfully");
      setSelectedColorId(null);
    } catch (error) {
      console.error("Error adding/updating color:", error);
    }
  };

  // Image Upload handler
  const handleUploadChange = ({ fileList }) => {
    setFileList(fileList);
  };

  // Image Upload API Call
  const sendColorDetails = async () => {
    try {
      const formDataToSend = new FormData();
      formDataToSend.append("action", "addImage");
      formDataToSend.append("token", token);
      formDataToSend.append("product_image_type", "demo");
      formDataToSend.append("product_color_id_refff",selectedColorId);
      

      fileList.forEach((file) => {
        formDataToSend.append("product_image[]", file.originFileObj);
      });

      const response = await axios.post(
        "https://jutiepie.in/api/product.php",
        formDataToSend
      );
      console.log("API response:", response.data);
      alert("Image updated successfully");
    } catch (error) {
      console.error("Error uploading images:", error);
    }
  };
  

  // Tabs items
  const items = [
    {
      key: "1",
      label: "Product",
      children: (
        <div className="product">
          <Form
            form={form}
            name="wrap"
            labelCol={{ flex: "110px" }}
            labelAlign="left"
            labelWrap
            wrapperCol={{ flex: 1 }}
            colon={false}
            style={{ maxWidth: 600 }}
            onFinish={handleFormSubmit}
          >
            <Form.Item
              label="Product Name:"
              name="name"
              rules={[{ required: true, message: "Please input the product name!" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Product Desc:"
              name="description"
              rules={[{ required: true, message: "Please input the product description!" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Tax:"
              name="tax"
              rules={[{ required: true, message: "Please input the tax!" }]}
            >
              <InputNumber />
            </Form.Item>
            <Form.Item
              label="Product Type:"
              name="product_type"
              rules={[{ required: true, message: "Please input the product type!" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Delivery Charges:"
              name="delivery_charges"
            >
              <InputNumber />
            </Form.Item>
            <Form.Item label=" ">
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      ),
    },
    {
      key: "2",
      label: "Size",
      children: (
        <div className="size">
          <Form
            form={form}
            name="size-form"
            labelCol={{ flex: "100px" }}
            labelAlign="left"
            labelWrap
            wrapperCol={{ flex: 1 }}
            colon={false}
            style={{ maxWidth: 600 }}
            onFinish={selectedSizeId ? updateProductDetails : sendProductDetails}
          >
            <Form.Item label="Width:" name="width" >
              <InputNumber />
            </Form.Item>
            <Form.Item label="Height:" name="height" >
              <InputNumber />
            </Form.Item>
            <Form.Item label="Length:" name="length" >
              <InputNumber />
            </Form.Item>
            <Form.Item label="Weight:" name="weight" >
              <InputNumber />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 5 }}>
              <Space>
                <Button type="primary" htmlType="submit">
                  {selectedSizeId ? "Update Size" : "Add Size"}
                </Button>
                <Button onClick={() => setSelectedSizeId(null)}>Clear</Button>
              </Space>
            </Form.Item>
          </Form>
          <Divider />
          <List
            bordered
            dataSource={sizes}
            renderItem={(size) => (
              <List.Item
                actions={[
                  <Button onClick={() => {
                    setSelectedSizeId(size.Size_Id);
                    form.setFieldsValue({
                      width: size.Product_Width,
                      height: size.Product_Height,
                      length: size.Product_Length,
                      weight: size.Weight,
                    });
                  }}>Edit</Button>,
                ]}
              >
                Width: {size.Product_Width}, Height: {size.Product_Height}, Length: {size.Product_Length}, Weight: {size.Weight}
              </List.Item>
            )}
          />
        </div>
      ),
    },
    {
      key: "3",
      label: "Colors",
      children: (
        <>
          <Select
            value={selectedSizeId}
            onChange={
              setSelectedSizeId}
            placeholder="Select Size"
            style={{ marginBottom: "20px" }}
          >
            {sizes && sizes.length > 0 ? (
              sizes.map((size) => (
                <Select.Option key={size.Size_Id} value={size.Size_Id}>
                  {size.Product_Height} x {size.Product_Width} ({size.Weight})
                </Select.Option>
              ))
            ) : (
              <Option disabled>No sizes available</Option>
            )}
          </Select>

          <Select
            value={selectedColorId}
            onChange={(v) => setSelectedColorId(v)}
            placeholder="Select Color"
            style={{ marginBottom: "20px" }}
          >
            {colors && colors.length > 0 ? (
              colors.map((color) => (
                <Select.Option key={color.product_color_id} label={color.color_code} value={color.product_color_id}>
                  {color.color_code}
                </Select.Option>
              ))
            ) : (
              <Option disabled>No colors available</Option>
            )}
          </Select>

          <Form
            form={form}
            onFinish={sendcolorDetails}
            style={{ marginTop: "20px" }}
          >
            <Form.Item label="Color Name:" name="colorname">
              <Input />
            </Form.Item>
            <Form.Item label="Color Code:" name="colorcode">
              <Input />
            </Form.Item>
            <Form.Item label="Quantity:" name="quantity">
              <InputNumber />
            </Form.Item>
            <Form.Item label="Price:" name="price">
              <InputNumber />
            </Form.Item>
            <Form.Item label="Feature:" name="feature">
              <Input />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                {selectedColorId ? "Update Color" : "Add Color"}
              </Button>
            </Form.Item>
          </Form>

          <Divider />

          <Upload
  multiple
  fileList={fileList}
  onChange={handleUploadChange}
  beforeUpload={() => false}
  listType="picture"
>
  <Button icon={<UploadOutlined />}>Upload Images</Button>
</Upload>
          <Button
            type="primary"
            onClick={sendColorDetails}
            style={{ marginTop: "20px" }}
          >
            Upload All Images
          </Button>
        </>
      ),
    },
  ];

  return (
    <Tabs
      defaultActiveKey="1"
      items={items}
    />
  );
}