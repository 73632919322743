import React, { useState, useEffect } from 'react';
import { Pie, Line, Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend, ArcElement } from 'chart.js';

// Register the required components for Chart.js
ChartJS.register(CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend, ArcElement);

const Dashboard = () => {
  const [barData, setBarData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Product Sale',
        data: [],
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  });

  const [lineData, setLineData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Total Sales',
        data: [],
        fill: false,
        backgroundColor: 'rgb(75, 192, 192)',
        borderColor: 'rgba(75, 192, 192, 0.2)',
      },
    ],
  });

  const [dailySalesData, setDailySalesData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Daily Product Sales',
        data: [],
        backgroundColor: 'rgba(153, 102, 255, 0.2)',
        borderColor: 'rgba(153, 102, 255, 1)',
        borderWidth: 1,
      },
    ],
  });

  const [dailyRevenueData, setDailyRevenueData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Daily Revenue',
        data: [],
        backgroundColor: 'rgba(255, 159, 64, 0.2)',
        borderColor: 'rgba(255, 159, 64, 1)',
        borderWidth: 1,
      },
    ],
  });

  const [stateSalesData, setStateSalesData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Sales by State',
        data: [],
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
      },
    ],
  });

  const [citySalesData, setCitySalesData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Sales by City',
        data: [],
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1,
      },
    ],
  });

  const [cityRevenueData, setCityRevenueData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Revenue by City',
        data: [],
        backgroundColor: 'rgba(255, 206, 86, 0.2)',
        borderColor: 'rgba(255, 206, 86, 1)',
        borderWidth: 1,
      },
    ],
  });

  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState('');

  useEffect(() => {
    fetch('https://jutiepie.in/api/order-product.php?action=fetchTopSoldProducts')
      .then(response => response.json())
      .then(data => {
        if (data.status === 'success') {
          const labels = data.data.map(item => item.Product_Name);
          const values = data.data.map(item => parseInt(item.sold_quantity, 10));

          setBarData({
            labels,
            datasets: [
              {
                label: 'Product Sale',
                data: values,
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
              },
            ],
          });
        }
      })
      .catch(error => console.error('Error fetching top sold products data:', error));

    fetch('https://jutiepie.in/api/order-product.php?action=fetchMonthlyTotalSales')
      .then(response => response.json())
      .then(data => {
        if (data.status === 'success') {
          const labels = data.data.map(item => item.sales_month);
          const values = data.data.map(item => parseInt(item.total_sales, 10));

          setLineData({
            labels,
            datasets: [
              {
                label: 'Total Sales',
                data: values,
                fill: false,
                backgroundColor: 'rgb(75, 192, 192)',
                borderColor: 'rgba(75, 192, 192, 0.2)',
              },
            ],
          });
        }
      })
      .catch(error => console.error('Error fetching monthly total sales data:', error));

    fetch('https://jutiepie.in/api/order-product.php?action=fetchProductSalesByState')
      .then(response => response.json())
      .then(data => {
        if (Array.isArray(data)) {
          const labels = data.map(item => item.state);
          const values = data.map(item => parseInt(item.total_sales, 10));

          setStateSalesData({
            labels,
            datasets: [
              {
                label: 'Sales by State',
                data: values,
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                borderColor: 'rgba(255, 99, 132, 1)',
                borderWidth: 1,
              },
            ],
          });
        }
      })
      .catch(error => console.error('Error fetching state-wise sales data:', error));

    fetch('https://jutiepie.in/api/order-product.php?action=fetchProductSalesByCity')
      .then(response => response.json())
      .then(data => {
        if (data.status === 'success') {
          const labels = data.data
            .filter(item => item.city && isNaN(item.city))
            .map(item => item.city);
          const values = data.data
            .filter(item => item.city && isNaN(item.city))
            .map(item => parseInt(item.sales_count, 10));

          setCitySalesData({
            labels,
            datasets: [
              {
                label: 'Sales by City',
                data: values,
                backgroundColor: 'rgba(54, 162, 235, 0.2)',
                borderColor: 'rgba(54, 162, 235, 1)',
                borderWidth: 1,
              },
            ],
          });
        }
      })
      .catch(error => console.error('Error fetching city-wise sales data:', error));

    fetch('https://jutiepie.in/api/order-product.php?action=fetchRevenueByCity')
      .then(response => response.json())
      .then(data => {
        if (data.status === 'success') {
          const labels = data.data
            .filter(item => item.city && isNaN(item.city))
            .map(item => item.city);
          const values = data.data
            .filter(item => item.city && isNaN(item.city))
            .map(item => parseInt(item.total_revenue, 10));

          setCityRevenueData({
            labels,
            datasets: [
              {
                label: 'Revenue by City',
                data: values,
                backgroundColor: 'rgba(255, 206, 86, 0.2)',
                borderColor: 'rgba(255, 206, 86, 1)',
                borderWidth: 1,
              },
            ],
          });
        }
      })
      .catch(error => console.error('Error fetching city-wise revenue data:', error));

    fetch('https://jutiepie.in/api/product.php?action=fetch_all_products')
      .then(response => response.json())
      .then(data => {
        if (data.list && Array.isArray(data.list)) {
          const productOptions = data.list.map(product => ({
            value: product.Product_Name,
            label: product.Product_Name,
          }));
          setProducts(productOptions);
        }
      })
      .catch(error => console.error('Error fetching products:', error));
  }, []);

  useEffect(() => {
    if (selectedProduct) {
      fetch(`https://jutiepie.in/api/order-product.php?action=fetch_product_details_by_name&product_name=${encodeURIComponent(selectedProduct)}`)
        .then(response => response.json())
        .then(data => {
          const dailyLabels = data.map(item => item.Day);
          const dailySalesValues = data.map(item => parseInt(item.Products_Sold, 10));
          const dailyRevenueValues = data.map(item => parseInt(item.Revenue, 10));
          const cityLabels = data.map(item => item.city);
          const citySalesValues = data.map(item => parseInt(item.Sales_By_City, 10));
          const cityRevenueValues = data.map(item => parseInt(item.Revenue_By_City, 10));

          setDailySalesData({
            labels: dailyLabels,
            datasets: [
              {
                label: 'Daily Product Sales',
                data: dailySalesValues,
                backgroundColor: 'rgba(153, 102, 255, 0.2)',
                borderColor: 'rgba(153, 102, 255, 1)',
                borderWidth: 1,
              },
            ],
          });

          setDailyRevenueData({
            labels: dailyLabels,
            datasets: [
              {
                label: 'Daily Revenue',
                data: dailyRevenueValues,
                backgroundColor: 'rgba(255, 159, 64, 0.2)',
                borderColor: 'rgba(255, 159, 64, 1)',
                borderWidth: 1,
              },
            ],
          });

          setCitySalesData({
            labels: cityLabels,
            datasets: [
              {
                label: 'Sales by City',
                data: citySalesValues,
                backgroundColor: 'rgba(54, 162, 235, 0.2)',
                borderColor: 'rgba(54, 162, 235, 1)',
                borderWidth: 1,
              },
            ],
          });

          setCityRevenueData({
            labels: cityLabels,
            datasets: [
              {
                label: 'Revenue by City',
                data: cityRevenueValues,
                backgroundColor: 'rgba(255, 206, 86, 0.2)',
                borderColor: 'rgba(255, 206, 86, 1)',
                borderWidth: 1,
              },
            ],
          });
        })
        .catch(error => console.error('Error fetching product details by name:', error));
    }
  }, [selectedProduct]);

  const barOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return context.dataset.label + ': ' + context.raw;
          },
        },
      },
    },
  };

  const lineOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return context.dataset.label + ': ' + context.raw;
          },
        },
      },
    },
  };

  const dailySalesOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return context.dataset.label + ': ' + context.raw;
          },
        },
      },
    },
  };

  const dailyRevenueOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return context.dataset.label + ': ' + context.raw;
          },
        },
      },
    },
  };

  const stateSalesOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return context.dataset.label + ': ' + context.raw;
          },
        },
      },
    },
  };

  const citySalesOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return context.dataset.label + ': ' + context.raw;
          },
        },
      },
    },
  };

  const cityRevenueOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return context.dataset.label + ': ' + context.raw;
          },
        },
      },
    },
  };

  return (
    <div>
      <div className="row">
        <div className="col-12">
          <label htmlFor="productSelect" style={{ marginBottom: '20px' }}>Select Product:</label>
          <select
            id="productSelect"
            value={selectedProduct}
            style={{ padding: '3px', borderRadius: '25px' }}
            onChange={e => setSelectedProduct(e.target.value)}
          >
            <option value="">Select a product</option>
            {products.map(product => (
              <option key={product.value} value={product.value}>
                {product.label}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <Bar data={barData} options={barOptions} />
        </div>
        <div className="col-6">
          <Line data={lineData} options={lineOptions} />
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <Bar data={dailySalesData} options={dailySalesOptions} />
        </div>
        <div className="col-6">
          <Line data={dailyRevenueData} options={dailyRevenueOptions} />
        </div>
        <div className="col-6">
          <Bar data={stateSalesData} options={stateSalesOptions} />
        </div>
        <div className="col-6">
          <Bar data={citySalesData} options={citySalesOptions} />
        </div>
        <div className="col-6">
          <Bar data={cityRevenueData} options={cityRevenueOptions} />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
