import { Button, Divider, Form, Input, List, Select } from "antd";
import { useForm } from "antd/es/form/Form";
import React, { useEffect, useState } from "react";
import { FaTrashAlt } from "react-icons/fa";
import { MdOutlineCurrencyRupee } from "react-icons/md";
import { useSelector } from "react-redux";
import axios from "axios";
import { SketchPicker } from "react-color"; // Import SketchPicker from react-color
import Product from "./Product";

export default function ProductColor({
  productList,
  setProductList,
  setActiveButton,
}) {
  const [colorList, setColorList] = useState([]);
  const [selectedSize, setSelectedSize] = useState(null);
  const [selectedColorCode, setSelectedColorCode] = useState("#000000"); // Default color
  const { token } = useSelector((state) => state.auth);
  const [size, setSize] = useState([]);
  const [form] = useForm(); // Use Form instance
  const mainproductlist = productList.productId;

  const handleSizeSelect = (value) => {
    setSelectedSize(value);
  };

  const handleColorChangeComplete = (color) => {
    setSelectedColorCode(color.hex); // Set the selected color code
    console.log("Selected color code:", color.hex); // Console log the color code
  };

  const handleAddColor = (values) => {
    const previousId =
      colorList.length > 0 ? colorList[colorList.length - 1].id : 0;

    const colorData = {
      id: previousId + 1,
      colorCode: selectedColorCode, // Use dynamically picked color code
      colorName: values.colorname,
      quantity: values.quantity,
      price: values.price,
      sizeId: selectedSize,
    };

    const updatedColorList = [...colorList, colorData];

    setColorList(updatedColorList);

    setProductList((prevProductList) => ({
      ...prevProductList,
      size: prevProductList.size.map((sizeItem) => {
        if (sizeItem.id === selectedSize) {
          return {
            ...sizeItem,
            color: updatedColorList,
          };
        }
        return sizeItem;
      }),
    }));

    form.resetFields();
  };

  ///// This is for add product /////
  const sendcolorDetails = async () => {
    try {
      for (const color of colorList) {
        const formDataToSend = new FormData();
        formDataToSend.append("action", "AddColor");
        formDataToSend.append("token", token);
        formDataToSend.append("color_name", color.colorName);
        formDataToSend.append("color_code", color.colorCode); // Send dynamic color code
        formDataToSend.append("product_qty", color.quantity);
        formDataToSend.append("product_feature", "xyz");
        formDataToSend.append("product_price", color.price);
        formDataToSend.append("Size_Id", color.sizeId);

        const response = await axios.post(
          "https://jutiepie.in/api/product.php",
          formDataToSend
        );

        console.log("API response for color:", response.data);
      }

      // All API calls have been made, move to the next step
      setActiveButton("image");
    } catch (error) {
      console.error("Error sending product color to the API:", error);
    }
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `https://jutiepie.in/api/product.php?action=productSizeFetch&token=${token}&Product_Id=${mainproductlist}`
      );

      if (response) {
        setSize(response.data.data);
        console.log(response.data.data);
      }
    } catch (error) {
      console.error("Error making get request", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <Select onChange={handleSizeSelect} style={{ width: "100%" }}>
        {Array.isArray(size) ? (
          size.map((size) => (
            <Option key={size.Size_Id} value={size.Size_Id}>
              Width {size.Product_Width}* Height {size.Product_Height}
            </Option>
          ))
        ) : (
          <p>No size available</p>
        )}
      </Select>

      <List
        header={<h5>Product Colors</h5>}
        dataSource={colorList.filter((value) => value.sizeId === selectedSize)}
        style={{ minHeight: "400px" }}
        renderItem={(item, index) => (
          <List.Item>
            <h6>
              ( {index + 1})&nbsp;{item.colorName}&nbsp;({item.quantity})&nbsp;
              <MdOutlineCurrencyRupee />
              {item.price}
            </h6>
            <Button danger>
              <FaTrashAlt />
            </Button>
          </List.Item>
        )}
      />

      <Form
        form={form}
        onFinish={handleAddColor}
        layout="vertical"
        className="mt-5"
        style={{ position: "sticky", bottom: "0", background: "#fff" }}
      >
        <Divider />
        <h5>Add new Color</h5>
        <div className="row">
          <div className="col-md-6">
            <Form.Item name="color">
              <SketchPicker
                color={selectedColorCode}
                onChangeComplete={handleColorChangeComplete} // Use onChangeComplete from react-color
              />
            </Form.Item>
          </div>
          <div className="col-md-6">
            <Form.Item
              name="colorname"
              rules={[{ required: true, message: "Color name is required" }]}
            >
              <Input
                placeholder="Color name"
                style={{ width: "100%" }}
                min={0}
              />
            </Form.Item>
          </div>
          <div className="col-md-6">
            <Form.Item
              name="quantity"
              rules={[
                { required: true, message: "Available quantity is required" },
              ]}
            >
              <Input placeholder="Quantity" style={{ width: "100%" }} min={0} />
            </Form.Item>
          </div>
          <div className="col-md-4">
            <Form.Item
              name="price"
              rules={[{ required: true, message: "Price is required" }]}
            >
              <Input placeholder="Price" style={{ width: "100%" }} min={0} />
            </Form.Item>
          </div>
          <div className="col-md-2">
            <Form.Item>
              <Button
                style={{ background: "green", color: "#fff" }}
                htmlType="submit"
              >
                Add
              </Button>
            </Form.Item>
          </div>
        </div>
        <Form.Item className="mt-4">
          <Button
            onClick={() => setActiveButton("size")}
            style={{ color: "#fff", background: "#000" }}
          >
            Previous
          </Button>
          <Button
            onClick={sendcolorDetails}
            disabled={colorList && colorList.length === 0}
            style={{ color: "#fff", background: "green" }}
          >
            Next
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
